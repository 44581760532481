import React from 'react';
import { useWindowSize } from 'react-use';
import theme from '../styles/theme';

export const Desktop = ({ children }) => {
  const { width } = useWindowSize();
  return width > parseInt(theme.desktop, 10) ? children : null;
};

export const Tablet = ({ children }) => {
  const { width } = useWindowSize();
  return width > parseInt(theme.tablet, 10) ? children : null;
};

export const HiddenMobile = ({ children }) => <Tablet>{children}</Tablet>;

export const Mobile = ({ children }) => {
  const { width } = useWindowSize();
  const isMobile = width < parseInt(theme.tablet, 10) - 1;
  return isMobile ? children : null;
};
