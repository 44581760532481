import { Link } from 'gatsby';
import React from 'react';

import ExternalLink from '../../components/ExternalLink';

const MobileNavEnd = ({ navContent }) => (
  <div className="navbar-end is-hidden-desktop">
    <hr className="navbar-divider" />
    <ExternalLink
      className="navbar-item"
      href="https://app.subscribepro.com/login"
    >
      Login
    </ExternalLink>
    <ExternalLink className="navbar-item" href="https://www.subscribepro.com">
      Company
    </ExternalLink>
    <ExternalLink
      className="navbar-item"
      href="https://www.subscribepro.com/support/"
    >
      Support
    </ExternalLink>
    <hr className="navbar-divider" />
    {navContent.map(el => (
      <div key={el.label} className="navbar-item">
        {el.label}
        <div className="navbar-dropdown">
          {el.children.map(el2 => (
            <Link
              key={el2.to}
              className="navbar-item"
              activeClassName="is-active"
              to={el2.to}
            >
              {el2.label}
            </Link>
          ))}
        </div>
      </div>
    ))}
  </div>
);

export default MobileNavEnd;
