import React from 'react';

const SubscribeProS = ({ className }) => (
  <svg
    className={className}
    width="780"
    height="780"
    viewBox="0 -110 780 780"
    fill="#173D38"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: '40px', height: 'auto' }}
  >
    <rect x="0" y="0" width="780" height="560" fill="#173D38" />
    <path
      d="M650.8703 110.0635H490.9065L380.5039 220.4661H540.4677V330.5059H380.5039V440.9085H650.8703V110.0635Z"
      fill="#24B286"
    />
    <path
      d="M380.5041 330.5059H220.5404H220.5403V220.4661H220.5404H380.5041V110.0635H110.1377V440.9085H270.1015L380.5041 330.5059Z"
      fill="#24B286"
    />
  </svg>
);
export default SubscribeProS;
