import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import DocSearch from '../../components/DocSearch.js';
import SearchIcon from '../../svg/search';
import SubscribeProS from '../../components/SubscribeProS';
import DesktopNavEnd from './DesktopNavEnd';
import MobileNavEnd from './MobileNavEnd';
import { Mobile } from '../../util/responsive';
import usePrevious from '../../hooks/usePrevious.js';

const Wrapper = styled.div`
  .navbar-brand {
    margin-right: 2rem;
    align-items: center;

    .subscribepro-s {
      height: 30px;
      width: 30px;
      fill: ${props => props.theme.primary};
    }
    a span {
      font-weight: 700;
      margin-left: 0.8rem;
    }
    a,
    a:link,
    a:hover,
    a:visited,
    a:link {
      line-height: 1;
      color: ${props => props.theme.greyDark};
    }

    .search-box {
      margin: 0 0 0 3rem;
    }
  }

  .navbar-link figure {
    margin: 0 1em 0 0;
  }

  .search-box {
    .field {
      width: 20rem;
      input {
        width: 20rem;
      }
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1152px) {
    .search-box {
      .field {
        width: 17rem;
        input {
          width: 17rem;
        }
      }
    }
  }

  .navbar-item,
  .navbar-link {
    svg {
      fill: ${props => props.theme.greyDark};
    }
  }
  a.navbar-item:hover,
  a.navbar-item.is-active,
  .navbar-link:hover,
  .navbar-link.is-active {
    svg {
      fill: ${props => props.theme.spBlue};
    }
  }

  .is-hidden-desktop {
    .navbar-divider {
      display: block;
      margin: 0.5rem 0.75rem;
      border-top: 1px solid whitesmoke;
      background-color: transparent;
    }
  }

  @media screen and (max-width: ${props => props.theme.widescreen}) {
    .navbar > .container .navbar-brand {
      margin-left: 0;
    }
  }

  .navbar-end {
    margin-right: 0.75rem;
  }
`;

const SearchBox = ({ searchNumber }) => (
  <div className="search-box">
    <div className="field">
      <p className="control has-icons-left">
        <DocSearch searchNumber={searchNumber} />
        <span className="icon is-small is-left">
          <SearchIcon />
        </span>
      </p>
    </div>
  </div>
);

const Nav = ({ mobileNavContent, curPath }) => {
  const prevPath = usePrevious(curPath);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const toggleMobileNav = () => {
    setShowMobileNav(!showMobileNav);
  };
  const hideMobileNav = () => {
    setShowMobileNav(false);
  };
  const keyPressed = e => {
    console.log(e.key);
    if (e.key === 'Enter' || e.key === ' ') {
      toggleMobileNav();
    }
  };
  useEffect(() => {
    if (curPath !== prevPath) {
      hideMobileNav();
    }
  });

  return (
    <Wrapper>
      <nav
        className="main-nav navbar is-fixed-top has-shadow"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container is-widescreen">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <SubscribeProS />
              <span>Subscribe Pro Docs</span>
            </Link>

            <div className="is-hidden-mobile">
              <SearchBox searchNumber={1} />
            </div>

            <div
              role="button"
              tabIndex="0"
              aria-label="menu"
              aria-expanded={showMobileNav ? 'true' : 'false'}
              className={
                'navbar-burger burger ' + (showMobileNav ? 'is-active' : '')
              }
              data-target="main-nav"
              onClick={toggleMobileNav}
              onKeyPress={keyPressed}
            >
              <span />
              <span />
              <span />
            </div>
          </div>

          <div
            id="main-nav"
            className={'navbar-menu ' + (showMobileNav ? 'is-active' : '')}
          >
            <div className="navbar-start">
              <Mobile>
                <div className="navbar-item">
                  <SearchBox searchNumber={2} />
                </div>
              </Mobile>
            </div>

            <DesktopNavEnd />

            <MobileNavEnd navContent={mobileNavContent} />
          </div>
        </div>
      </nav>
    </Wrapper>
  );
};

export default Nav;
