import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCogs,
  faCog,
  faUserFriends,
  faShoppingBasket,
  faShoppingCart,
  faCode,
  faSyncAlt,
  faEnvelopeOpenText,
  faExclamationTriangle,
  faExclamationCircle,
  faCreditCard,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';

import {
  faGithub,
  faSalesforce,
} from '@fortawesome/free-brands-svg-icons';

import {
  faBrowser,
  faBallotCheck,
  faClipboardList,
  faCloud,
  faFileDownload,
} from '@fortawesome/pro-regular-svg-icons';

const fasIcons = [
  faCogs,
  faCog,
  faUserFriends,
  faShoppingBasket,
  faShoppingCart,
  faCode,
  faSyncAlt,
  faEnvelopeOpenText,
  faExclamationTriangle,
  faExclamationCircle,
  faCreditCard,
];

const farIcons = [
  faBrowser,
  faBallotCheck,
  faClipboardList,
  faCloud,
  faFileDownload,
  faFilePdf,
];

const fabIcons = [
  faGithub,
  faSalesforce,
];

library.add(...fasIcons, ...farIcons, ...fabIcons);
