import React from 'react';

const ExternalLink = props => {
  var { className, href, children, ...elemProps } = props;

  return (
    <a
      className={className}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      {...elemProps}
    >
      {children}
    </a>
  );
};

export default ExternalLink;
