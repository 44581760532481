import React from 'react';
import { Link } from 'gatsby';
import { Location } from '@reach/router';
import styled from 'styled-components';
import classNames from 'classnames';

import { Tablet, Mobile } from '../../util/responsive';

const Wrapper = styled.div`
  /* Z-index above main page (needed because this is fixed position), but below the navbar (needed for drop-down menus) */
  z-index: 10;
  position: fixed;
  top: ${(props) => props.theme.navbarHeight};
  left: 0;
  right: 0;
  height: ${(props) => props.theme.navbarHeight};
  min-height: ${(props) => props.theme.navbarHeight};

  display: flex;
  align-items: center;

  background-color: #F6F6F1;

  .container {
    .tabs {
      line-height: 1.4;
      li a {
        font-weight: bold;
      }
      li:is(.is-active) a {
        background-color: #24b286;
        border-color: #24b286;
      }
      li:is(.is-active) a:hover {
        background-color: #28c796;
      }
      li:not(.is-active) a {
        background-color: #fff;
      }
    }
    @media screen and (max-width: ${(props) => props.theme.widescreen}) {
      .tabs {
        margin: 0 0.75rem;
      }
    }
  }
  @media screen and (max-width: ${(props) => props.theme.mobile}) {
    .container {
      display: flex;
      justify-content: center;
    }
  }
`;

const Tab = ({ to, children }) => (
  <Location>
    {({ location }) => {
      const isTabActive = location.pathname.startsWith(to);
      return (
        <li className={classNames({ 'is-active': isTabActive })}>
          <Link to={to}>{children}</Link>
        </li>
      );
    }}
  </Location>
);

const SectionNav = () => {
  return (
    <Wrapper className="section-nav">
      <div className="container is-widescreen">
        <div className="tabs is-toggle">
          <ul>
            <Tab to="/users/">
              <Tablet>
                <span>Merchant User's Guide</span>
              </Tablet>
              <Mobile>
                <span>Merchant</span>
              </Mobile>
            </Tab>
            <Tab to="/integrations/">
              <Tablet>
                <span>eCommerce Platform Integrations</span>
              </Tablet>
              <Mobile>
                <span>Integrations</span>
              </Mobile>
            </Tab>
            <Tab to="/technical/">
              <Tablet>
                <span>Technical Developer Docs</span>
              </Tablet>
              <Mobile>
                <span>Developer</span>
              </Mobile>
            </Tab>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
};

export default SectionNav;
