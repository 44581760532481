import { navigate } from 'gatsby';
import React, { useEffect } from 'react';

const DocSearch = ({searchNumber}) => {
  useEffect(() => {
    const d = document.createElement('script');
    d.id = 'docsearch-script';
    d.async = true;
    d.src =
      'https://cdn.jsdelivr.net/npm/docsearch.js@2/dist/cdn/docsearch.min.js';

    d.onload = () => {
      window.docsearch({
        apiKey: '73c741ca4a6890a2acf694c9b9116080',
        appId: '5QEPJGSDPG',
        indexName: 'prod_docs',
        inputSelector: '#algolia-docsearch-' + searchNumber,
        handleSelected: function(input, event, { url }) {
          const { hash, pathname } = new URL(url);
          navigate(`${pathname}${hash}`);
        },
      });
    };
    document.getElementsByTagName('body')[0].appendChild(d);
  }, [searchNumber]);
  return (
    <input id={'algolia-docsearch-'+searchNumber} className="input" placeholder="Search docs..." />
  );
};

DocSearch.displayName = 'DocSearch';

export default DocSearch;
