import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled, { ThemeProvider } from 'styled-components';

import '../util/fontawesome';

// Base styling
import '../styles/base.scss';
// Theme for styled-components
import theme from '../styles/theme';
import Page from './Page';
// Load Prism styling
require('../styles/bulma-resets-for-prism.scss');
require('prismjs/plugins/line-numbers/prism-line-numbers.css');
require('../styles/prism-subscribepro-light.scss');


const vars = {
  // Set width for side cols
  leftColWidth: '280px',
  rightColWidth: '220px',
  // These container widths are from Bulma
  containerMaxWidthDesktop: '960px',
  containerMaxWidthWidescreen: '1152px',
  containerMaxWidthFullHD: '1344px',
};

// All CSS for responsive 1, 2 & 3 col layout is done here
const Wrapper = styled.div`
  .page-content {
    /*
     * Fixed position for left & right side columns.
     * This allows for separate scrollbars for left & right cols, while main col uses main page scrollbar.content
     */
    .side-col {
      position: fixed;
      top: ${props => `calc(2 * ${props.theme.navbarHeight})`};
      bottom: 0;
      overflow: hidden;
      .inside {
        padding: 2rem 0;
        height: 100%;
        overflow: auto;
      }
    }
    .left,
    .right {
      display: none;
    }
    .left {
      left: 0;
      .inside {
        width: ${vars.leftColWidth};
      }
    }
    .right {
      right: 0;
      .inside {
        width: ${vars.rightColWidth};
      }
    }
    .left {
      background-color: ${props => props.theme.whiteTer};
    }
  }

  /*
   * Mobile
   * 1 col layout
   * Left and right cols hidden by default
   */
  @media screen and (max-width: ${props => props.theme.mobile}) {
  }

  /*
   * Tablet
   * 1 or 2 col layout
   */
  @media screen and (min-width: ${props =>
      props.theme.tablet}) and (max-width: ${props => props.theme.tabletMax}) {
    .page-content.has-left-col {
      .main {
        margin-left: ${vars.leftColWidth};
      }
      .left {
        display: block;
      }
    }
  }

  /*
   * Desktop, Widescreen, FullHD
   * 1, 2 or 3 col layout
   */
  @media screen and (min-width: ${props => props.theme.desktop}) {
    .page-content.has-left-col {
      .main {
        margin-left: ${vars.leftColWidth};
      }
      .left {
        display: block;
      }
    }
    .page-content.has-right-col {
      .main {
        margin-right: ${vars.rightColWidth};
      }
      .right {
        display: block;
      }
    }
  }

  @media screen and (min-width: ${vars.containerMaxWidthWidescreen}) {
    .left {
      padding-left: calc((50%) - (${vars.containerMaxWidthWidescreen} / 2));
    }
    .right {
      padding-right: calc((50%) - (${vars.containerMaxWidthWidescreen} / 2));
    }
  }

  @media screen and (min-width: ${props => props.theme.fullhd}) {
    .left {
      padding-left: calc((50%) - (${vars.containerMaxWidthFullHD} / 2));
    }
    .right {
      padding-right: calc((50%) - (${vars.containerMaxWidthFullHD} / 2));
    }
  }

  /*
   * Padding inside columns
   */
  .page-content {
    .main {
      .inside {
        padding: 1rem 0 4rem;
        overflow-x: hidden;
        @media screen and (max-width: ${props => props.theme.widescreen}) {
          padding: 1rem 3rem 4rem;
        }
      }
    }
    .left {
      .inside {
        padding-right: 0.75rem;
        @media screen and (max-width: ${props => props.theme.widescreen}) {
          padding-left: 0.75rem;
        }
      }
    }
    .right {
      .inside {
        @media screen and (max-width: ${props => props.theme.widescreen}) {
          padding-right: 0.75rem;
        }
      }
    }
  }
  .page-content.has-left-col {
    .main {
      .inside {
        padding-left: 3rem;
      }
    }
  }
  .page-content.has-right-col {
    .main {
      .inside {
        padding-right: 3rem;
      }
    }
  }
`;

const Layout = ({ data = {}, pageContext = {}, location, children }) => {
  const page = data.markdownRemark ? data.markdownRemark : {};
  const tableOfContents = page.tableOfContents ? page.tableOfContents : {};
  const sidebarNavContent = pageContext.sidebarNavContent
    ? pageContext.sidebarNavContent
    : [];
  const showLeftNav =
    pageContext.sidebarNavContent &&
    (page.frontmatter && page.frontmatter.showLeftNav !== null
      ? page.frontmatter.showLeftNav
      : true);
  const showRightNav =
    pageContext.sidebarNavContent &&
    (page.frontmatter && page.frontmatter.showRightNav !== null
      ? page.frontmatter.showRightNav
      : false);

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin={true} />
          <link
            href="https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,400&family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400&display=swap"
            rel="stylesheet"
          ></link>
          {/* <link
            href="https://fonts.googleapis.com/css?family=Open+Sans:400,400i,500,600,700"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Source+Code+Pro"
            rel="stylesheet"
          /> */}
          <body className="has-navbar-fixed-top" />
        </Helmet>
        <Page
          curPath={location.pathname}
          showLeftNav={showLeftNav}
          showRightNav={showRightNav}
          tableOfContents={tableOfContents}
          sidebarNavContent={sidebarNavContent}
        >
          {children}
        </Page>
      </Wrapper>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
