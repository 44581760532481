import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import Sidebar from './Sidebar';
import RightNav from './RightNav';
import Nav from './header/Nav';
import SectionNav from './header/SectionNav';

const Wrapper = styled.div``;

const Page = ({
  curPath,
  showLeftNav,
  showRightNav,
  tableOfContents,
  sidebarNavContent,
  children,
}) => {
  return (
    <Wrapper>
      <div className="header">
        <Nav mobileNavContent={sidebarNavContent} curPath={curPath} />
        <SectionNav />
      </div>

      <div
        className={classNames('page-content', {
          'has-left-col': showLeftNav,
          'has-right-col': showRightNav,
        })}
      >
        <div className="container is-widescreen">
          <div className="main">
            <div className="inside content">{children}</div>
          </div>

          {showLeftNav && (
            <div className="side-col left">
              <div className="inside">
                <Sidebar navContent={sidebarNavContent} />
              </div>
            </div>
          )}

          {showRightNav && (
            <div className="side-col right">
              <div className="inside">
                <RightNav tableOfContents={tableOfContents} />
              </div>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
