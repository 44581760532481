const gap = 32;
const tablet = 769;
const desktop = 960 + 2 * gap;
const widescreen = 1152 + 2 * gap;
const spBlue = '#24b286';
const spLightBlue = '#28c796';

const theme = {
  gap: `${gap}px`,
  tablet: `${tablet}px`,
  mobile: `${tablet - 1}px`,
  desktop: `${desktop}px`,
  tabletMax: `${desktop - 1}px`,
  widescreen: `${widescreen}px`,
  desktopMax: `${widescreen - 1}px`,
  fullhd: `${1344 + 2 * gap}px`,

  // Colors
  primary: '#e0970b',

  greyDarker: '#312f22',
  greyDark: '#5e5a41',
  grey: '#777677',
  greyLight: '#a6a5a6',
  greyLighter: '#d8d8d8',
  redError: '#9a221a',
  whiteTer: '#F6F6F1 !default',
  whiteBis: 'hsl(0, 0%, 98%) !default',

  spOrange: '#e0970b',
  spLightOrange: '#ffac0d',
  spBlue: spBlue,
  spLightBlue: spLightBlue,

  // Body text
  familySansSerif: `'DM Sans', sans-serif`,
  familySecondary: `'DM Sans', sans-serif`,
  familyCode: "'DM Mono', monospace",

  bodyLineHeight: 1.7,

  // Headings
  size1: '2rem',
  size2: '1.5rem',
  size3: '1.25rem',
  size4: '1.1rem',
  size5: '1rem',

  // Links
  link: `${spBlue}`,
  linkVisited: `${spBlue}`,
  linkFocusBorder: `${spBlue}`,
  linkHover: `${spLightBlue}`,

  // Navbar
  // $navbar-breakpoint: $tablet;
  navbarHeight: '4rem',
  navbarItemImgMaxHeight: '2rem',
  navbarPaddingHorizontal: '3rem',
};

export default theme;
