import React from 'react';

import GitHubMark from '../../svg/github-mark';
import ExternalLink from '../../components/ExternalLink';

const DesktopNavEnd = () => (
  <div className="navbar-end is-hidden-mobile is-hidden-tablet-only">
    <div className="navbar-item has-dropdown is-hoverable">
      <div className="navbar-link">
        <ExternalLink
          className="navbar-item"
          href="https://app.subscribepro.com/login"
        >
          Merchant App
        </ExternalLink>
      </div>
      <div className="navbar-dropdown ">
        <ExternalLink
          className="navbar-item"
          href="https://app.subscribepro.com/login"
        >
          Login
        </ExternalLink>
      </div>
    </div>
    <ExternalLink
      className="navbar-item"
      href="https://github.com/subscribepro"
    >
      <GitHubMark />
    </ExternalLink>
    <ExternalLink
      className="navbar-item"
      href="https://status.subscribepro.com/"
    >
      Status
    </ExternalLink>
    <div className="navbar-item has-dropdown is-hoverable">
      <div className="navbar-link">
        <ExternalLink
          className="navbar-item"
          href="https://www.subscribepro.com"
        >
          Company
        </ExternalLink>
      </div>
      <div className="navbar-dropdown ">
        <ExternalLink
          className="navbar-item"
          href="https://www.subscribepro.com/about"
        >
          About
        </ExternalLink>
        <ExternalLink
          className="navbar-item"
          href="https://www.subscribepro.com/contact"
        >
          Contact
        </ExternalLink>
        <hr className="navbar-divider" />
        <ExternalLink
          className="navbar-item"
          href="https://www.subscribepro.com/support"
        >
          Support
        </ExternalLink>
      </div>
    </div>
  </div>
);

export default DesktopNavEnd;
