import React from 'react';
import styled from 'styled-components';

const NavWrapper = styled.nav`
  font-size: 0.9rem;
`;

const decorateTableOfContentsHtml = tableOfContents => {
  var htmlOut = tableOfContents;
  htmlOut = htmlOut.replace('<ul', '<ul class="menu-list"');

  return htmlOut;
};

const RightNav = ({ tableOfContents }) => (
  <NavWrapper className="menu">
    {typeof tableOfContents === 'string' && (
      <div
        dangerouslySetInnerHTML={{
          __html: decorateTableOfContentsHtml(tableOfContents),
        }}
      />
    )}
  </NavWrapper>
);

export default RightNav;
