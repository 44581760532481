import React from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Location } from '@reach/router';
import classNames from 'classnames';

import useMeasure from '../hooks/useMeasure';
import usePrevious from '../hooks/usePrevious';

const NavWrapper = styled.aside`
  .sidebar-card {
    border: 1px #eee solid;
    border-radius: 6px;
    margin: 2rem -0.75em 0 0;
    background-color: #eee;
    padding: 2rem 1rem;
    .menu-label {
      padding-left: 0.75rem;
    }
  }
  .sub-menu-container {
    overflow: hidden;
  }
`;

const SubMenu = ({ subEl, alwaysShowSubNav, curPath }) => {
  const isOpen = (curPath && curPath.startsWith(subEl.to)) || alwaysShowSubNav;
  const prevIsOpen = usePrevious(isOpen);
  const hasSubMenu = subEl.children && subEl.children.length > 0;
  const [measureBind, measure] = useMeasure();
  const openStyle = {
    opacity: 1,
    height: measure.height + 24, // 12px top & bottom margin
    transform: `translate3d(0px,0,0)`,
  };
  const closedStyle = {
    opacity: 0,
    height: 0,
    transform: `translate3d(20px,0,0)`,
  };
  const animation = useSpring({
    immediate: isOpen === prevIsOpen,
    from: isOpen ? openStyle : closedStyle,
    to: isOpen ? openStyle : closedStyle,
  });

  if (!hasSubMenu) {
    return <></>;
  }

  return (
    <animated.div
      className="sub-menu-container"
      style={{ opacity: animation.opacity, height: animation.height }}
    >
      <ul
        className={classNames('sub-menu', { 'is-open': isOpen })}
        {...measureBind}
      >
        {subEl.children.map(el3 => (
          <animated.li
            key={el3.label}
            style={{ transform: animation.transform }}
          >
            <Link activeClassName="is-active" to={el3.to}>
              {el3.label}
            </Link>
          </animated.li>
        ))}
      </ul>
    </animated.div>
  );
};

const Menu = ({ el, alwaysShowSubNav, curPath }) => (
  <>
    {el.label && el.label.length > 0 && (
      <p className="menu-label">{el.label}</p>
    )}
    {el.children && el.children.length > 0 && (
      <ul className="menu-list">
        {el.children.map(el2 => (
          <li key={el2.label}>
            <Link activeClassName="is-active" to={el2.to}>
              {el2.label}
            </Link>
            <SubMenu
              subEl={el2}
              alwaysShowSubNav={alwaysShowSubNav}
              curPath={curPath}
            />
          </li>
        ))}
      </ul>
    )}
  </>
);

const Menus = ({ navContent, curPath }) => {
  return navContent.map(el => (
    <React.Fragment key={el.label}>
      {el.useCardStyle ? (
        <div className="sidebar-card">
          <Menu el={el} />
        </div>
      ) : (
        <Menu
          el={el}
          alwaysShowSubNav={navContent.alwaysShowSubNav || false}
          curPath={curPath}
        />
      )}
    </React.Fragment>
  ));
};

const Sidebar = ({ navContent }) => (
  <Location>
    {({ location }) => (
      <NavWrapper className="menu">
        <Menus navContent={navContent} curPath={location.pathname} />
      </NavWrapper>
    )}
  </Location>
);

export default Sidebar;
